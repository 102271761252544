import { graphql, Link } from "gatsby"
import moment from "moment"
import { rgba } from "polished"
import React from "react"
import styled from "styled-components"

import Container from "$components/Container"
import Footer from "$components/Footer"
import Header from "$components/Header"
import SEO from "$components/SEO"

const Article = styled(Container).attrs({ as: "article" })`
  max-width: 960px;
`

const AuthorText = styled.p`
  color: ${props => rgba(props.theme.tundora, 0.5)};

  @media only screen and ${props => props.theme.deviceSizes.mobile} {
    width: 100%;
  }
`

const PostImg = styled.img`
  width: 100%;
`

const Breadcrumbs = styled(Container)`
  font-size: 1.1rem;
  margin: 1rem auto;

  ${Link} {
    color: ${props => props.theme.tundora};
  }
`

const StyledContent = styled.div`
  margin-top: 20px;

  h2 {
    font-size: 1.5rem;
    margin: 2rem 0;
    text-align: left;
  }

  p {
    margin: 2rem 0;
  }

  ul {
    font-size: 1.2rem;
    margin: 1rem auto;
    width: 80%;

    li {
      margin: 0.5rem 0;
    }
  }
`

const H1 = styled.h1`
  color: ${props => props.theme.nero};
  font-size: 2.5rem;

  @media only screen and ${props => props.theme.deviceSizes.mobile} {
    width: 100%;
  }
`

const ArticleTemplate = ({ data }) => (
  <>
    <SEO
      description={data.wpcontent.post.excerpt}
      title={`${data.wpcontent.post.title} | Lotus Network Solutions`}
    />
    <Header />
    <Breadcrumbs>
      &#171; Back to <Link to="/insights/">Insights</Link> /{" "}
      <Link to="/articles/">Articles</Link>
    </Breadcrumbs>
    <main>
      <Article>
        <H1>{data.wpcontent.post.title}</H1>
        <PostImg
          alt={data.wpcontent.post.featuredImage.altText}
          src={data.wpcontent.post.featuredImage.mediaItemUrl}
        />
        <AuthorText>
          Written by {data.wpcontent.post.author.name} on{" "}
          <time datetime={data.wpcontent.post.date}>
            {moment(data.wpcontent.post.date).format("MMMM Do YYYY")}
          </time>
        </AuthorText>
        <StyledContent
          dangerouslySetInnerHTML={{ __html: data.wpcontent.post.content }}
        />
      </Article>
    </main>
    <Footer />
  </>
)

export default ArticleTemplate

export const query = graphql`
  query($id: ID!) {
    wpcontent {
      post(id: $id) {
        author {
          name
        }
        content
        date
        excerpt
        featuredImage {
          altText
          mediaItemUrl
        }
        title
      }
    }
  }
`
